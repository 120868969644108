import { Button } from "../Button";
import { Contacts } from "../Contacts";
import styles from "./Main.module.css";
import commonStyles from "../Common/Common.module.css";

export const Main = () => {
  return (
    <main className={styles.container}>
      <div className={commonStyles.wrapper}>
        <div className={styles.wrapper}>
          <h1 className={styles.heading}>
            Готовые решения для&nbsp;финансовых организаций
          </h1>
          <div className={styles.numbers}>
            <Contacts />
          </div>
          <Button
            onClick={() => {
              window.location.href = "#services";
            }}
          >
            Посмотреть каталог услуг
          </Button>
        </div>
      </div>
    </main>
  );
};
