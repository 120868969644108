import styles from "./Feedback.module.css";
export const Feedback = (data) => {
  const { name, description } = data;

  return (
    <div className={styles.container}>
      <p className={styles.name}>{name}</p>
      <p className={styles.description}>{description}</p>
    </div>
  );
};
