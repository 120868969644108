import { SectionHeading } from "../SectionHeading";
import styles from "./FeedbackList.module.css";
import commonStyles from "../Common/Common.module.css";

import { FEEDBACK_LIST } from "../../constants";
import { Feedback } from "../Feedback";
import { useRef } from "react";
import { useScroll } from "../../hooks/useScroll";
import { Button } from "../Button";
import { ArrowIcon } from "../../img/icons";

export const FeedbackList = () => {
  const ref = useRef(null);
  const { scrollLeft, scrollRight } = useScroll(ref);

  return (
    <section className={styles.container}>
      <div
        className={commonStyles.wrapper}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <SectionHeading>Отзывы наших клиентов</SectionHeading>
        <div className={commonStyles.sliderButtons}>
          <Button onClick={scrollRight}>К началу</Button>
          <Button
            onClick={scrollLeft}
            style={{ display: "flex", alignItems: "center", gap: 4 }}
          >
            <span>Прокрутить</span> <ArrowIcon />
          </Button>
        </div>
      </div>
      <div className={styles.carusel} ref={ref}>
        {FEEDBACK_LIST.map((feedback) => (
          <Feedback key={feedback.name} {...feedback} />
        ))}
      </div>
    </section>
  );
};
