import { whatsupLink, whatsupText } from "../../constants";
import { Button } from "../Button";
import { Contacts } from "../Contacts";
import { SocialMedia } from "../SocialMedia";
import styles from "./Footer.module.css";
export const Footer = () => {
  const onClick = () => {
    const text = whatsupText ? `?text=${whatsupText.replace(" ", "%20")}` : "";
    window.location = whatsupLink + text;
  };
  return (
    <div className={styles.container}>
      <div className={styles.logo}>
        <Button onClick={onClick}>Оставить заявку</Button>
      </div>

      <div className={styles.contacts}>
        <div className={styles.numbers}>
          <Contacts />
        </div>
        <SocialMedia />
      </div>
    </div>
  );
};
