import { SectionHeading } from "../SectionHeading";
import styles from "./AboutUs.module.css";
import commonStyles from "../Common/Common.module.css";
import { ABOUT_US, ABOUT_US_TITLE } from "../../constants";

export const AboutUs = () => {
  return (
    <section className={styles.container}>
      <div className={commonStyles.wrapper}>
        <SectionHeading>О нас</SectionHeading>
        <div>
          <p className={styles.title}>{ABOUT_US_TITLE}</p>
          <p className={styles.description}>{ABOUT_US}</p>
        </div>
      </div>
    </section>
  );
};
