import { SERVICES } from "../../constants";
import commonStyles from "../Common/Common.module.css";
import { SectionHeading } from "../SectionHeading";
import { Service } from "../Service";
import styles from "./Services.module.css";

export const Services = () => {
  return (
    <section className={styles.container} id="services">
      <div className={commonStyles.wrapper}>
        <SectionHeading>Наши услуги</SectionHeading>
        <div className={styles.servicesList}>
          {SERVICES.map((service, idx) => (
            <>
              <Service key={service.title} {...service} />
              {idx < SERVICES.length - 1 && <div className={styles.divider} />}
            </>
          ))}
        </div>
      </div>
    </section>
  );
};
