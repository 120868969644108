import { SectionHeading } from "../SectionHeading";
import styles from "./FeedBackForm.module.css";
import commonStyles from "../Common/Common.module.css";
import { useReducer } from "react";


export const FeedBackForm = () => {
  const reducer = (state, action) => {
    switch (action.type) {
      case 'change_name':
        return { ...state, name: action.payload };
      case 'change_user_organization':
        return { ...state, user_organization: action.payload };
      case 'change_user_comment':
        return { ...state, user_comment: action.payload };
      default:
        return state;
    }
  }

  const [store, dispatch] = useReducer(reducer, { name: '', user_organization : '', user_comment: '' });

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = {
      name: store.name,
      organization: store.user_organization,
      message: store.user_comment,
    };
  
    try {
      const response = await fetch('https://api.fk77.ru/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        alert('Сообщение отправлено!');
      } else {
        alert('Ошибка при отправке сообщения');
      }
    } catch (error) {
      console.error('Ошибка:', error);
    }
  };

  const onChangeNameHandler = (event) => {
    dispatch({ type: 'change_name', payload: event.target.value })
  }

  const onChangeOrganozationHandler = (event) => {
    dispatch({ type: 'change_user_organization', payload: event.target.value })
  }

  const onChangeCommentHandler = (event) => {
    dispatch({ type: 'change_user_comment', payload: event.target.value })
  }
  
  return (
    <section className={styles.container}>
      <div
        className={commonStyles.wrapper}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <SectionHeading>Свяжитесь с нами</SectionHeading>
       
      </div >
      <div className={commonStyles.wrapper}>
        <div className={styles.form}>
          <form onSubmit={handleSubmit} method="POST">

          <div className={styles.formGroup}>
            <label for="">Ваше имя</label>
            <input type="text" className={styles.formInput} value={store.name} onChange={onChangeNameHandler} name="user_name" placeholder="Ваше имя"/>
          </div>

          <div className={styles.formGroup}>
            <label for="">Ваша организация</label>
            <input type="text" className={styles.formInput}  value={store.user_organization} onChange={onChangeOrganozationHandler} name="user_organization" placeholder="Название организации"/>
          </div>

          <div className={styles.formGroup}>
            <label for="">Ваш коментарий</label>
            <input type="text" className={styles.formInput}  value={store.user_comment} onChange={onChangeCommentHandler} name="user_comment" placeholder="Ваш комментарий"/>
          </div>


          <button type="submit" className={styles.button}>Отправить форму</button>
          </form>
        </div>
      </div >
    </section>
  );
};
