import { SectionHeading } from "../SectionHeading";
import styles from "./Companies.module.css";
import commonStyles from "../Common/Common.module.css";

import { COMPANIES } from "../../constants";
import { Company } from "../Company";
import { Button } from "../Button";
import { useRef } from "react";
import { ArrowIcon } from "../../img/icons";
import { useScroll } from "../../hooks/useScroll";

export const Companies = () => {
  const ref = useRef(null);
  const { scrollLeft, scrollRight } = useScroll(ref);

  return (
    <section className={styles.container}>
      <div
        className={commonStyles.wrapper}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <SectionHeading>Компании в продаже</SectionHeading>
        <div className={commonStyles.sliderButtons}>
          <Button onClick={scrollRight}>К началу</Button>
          <Button
            onClick={scrollLeft}
            style={{ display: "flex", alignItems: "center", gap: 4 }}
          >
            <span>Прокрутить</span> <ArrowIcon />
          </Button>
        </div>
      </div>
      <div className={styles.carusel} ref={ref}>
        {COMPANIES.map((company) => (
          <Company key={company.inn} {...company} />
        ))}
      </div>
    </section>
  );
};
