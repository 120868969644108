export const useScroll = (ref) => {
  const scrollLeft = () =>
    ref.current.scrollBy({
      left: 900,
      behavior: "smooth",
    });

  const scrollRight = () =>
    ref.current.scrollBy({
      left: -100000900,
      behavior: "smooth",
    });

  return { scrollLeft, scrollRight };
};
