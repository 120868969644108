import whatsup from "../../img/whatsup.svg";
import telegram from "../../img/telegram.svg";
import signal from "../../img/signal.svg";

import styles from "./SocialMedia.module.css";
import { signalLink, telegramLink, whatsupLink } from "../../constants";

export const SocialMedia = () => {
  return (
    <div className={styles.container}>
      <a href={whatsupLink} target="_blank" rel="noreferrer">
        <img src={whatsup} alt="Вацап лого" />
      </a>
      <a href={telegramLink} target="_blank" rel="noreferrer">
        <img src={telegram} alt="Лого телеграм" />
      </a>
      <a href={signalLink} target="_blank" rel="noreferrer">
        <img src={signal} alt="Лого сигнал" />
      </a>
    </div>
  );
};
