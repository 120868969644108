import logo from "../../img/logo.png";
import { Contacts } from "../Contacts";
import { SocialMedia } from "../SocialMedia";
import styles from "./Header.module.css";
export const Header = () => {
  return (
    <div className={styles.container}>
      <div className={styles.logo}>
        <img src={logo} className={styles.img} alt="Логотип компании"></img>
        <p className={styles.slogan}>
          Ваш <span>надежный</span> партнер
        </p>
      </div>

      <div className={styles.contacts}>
        <div className={styles.numbers}>
          <Contacts />
        </div>
        <SocialMedia />
      </div>
    </div>
  );
};
